import {LineItemInput} from '@/cart/types';
import {VariantState} from '@/product/types';
import {signal} from '@preact/signals';

const mapping = signal<PriceMapping[]>([]);

/**
 * Sets the price mapping for the experiment.
 * This updates the global price test store with the new mapping,
 * so that the price of items can be updated in the UI and cart.
 *
 * @param newMapping - The new price mapping to be set.
 */
export function setPriceMapping(newMapping: PriceMapping[]) {
  mapping.value = newMapping;
}

/**
 * Retrieves the mapped price for the first pack.
 * This is useful for calculating 'compare at' prices for multipacks.
 *
 * @returns The new price for the first pack, or undefined if not found.
 */
export function getMappedOnePackPrice() {
  return mapping.value[0]?.newPrice;
}

/**
 * Maps a variant to its corresponding new price, if available.
 * If a new price is not available, the original price of the variant is used.
 * This allows us to display the experimental price in the UI.
 *
 * @param variant - The variant to be mapped.
 * @returns The variant with the mapped price.
 * @example
 * ```ts
 * const variant = {id: 123, price: 100};
 * const mappedVariant = mapVariant(variant);
 * console.log(mappedVariant.price); // 90
 * ```
 */
export function mapVariant(variant: VariantState) {
  const mappedVariant = mapping.value.find(
    (m) => m.variantId.toString() === variant.id.toString()
  );
  return {
    ...variant,
    price: mappedVariant?.newPrice ?? variant.price,
  };
}

/**
 * Maps a LineItemInput object to a new object with updated selling_plan property.
 * If the item is part of the price test, the selling_plan property is updated.
 * This allows us to silently alter the price of items as they are added to the cart.
 * Intended to be used with the cart store's `setMapLineItems` function.
 *
 * @param item - The LineItemInput object to be mapped.
 * @returns The mapped LineItemInput object with updated selling_plan property.
 * @example
 * ```ts
 * import {setMapLineItems} from '@/cart/cart-store';
 * import { mapLineItemInput } from '@/experiments/price-test/price-test';
 * 
 * // Line items will be mapped to the experimental selling plan
 * // before being added to the cart.
 * setMapLineItems(mapLineItemInput);
 * ```
 */
export function mapLineItemInput(item: LineItemInput) {
  const mappedItem = mapping.value.find(
    (m) =>
      m.variantId.toString() === item.id.toString() &&
      m.initialPlanId.toString() === item.selling_plan?.toString()
  );
  return {
    ...item,
    selling_plan: mappedItem?.newPlanId ?? item.selling_plan,
  };
}

/**
 * Represents a price mapping for a product variant.
 * This object configures the selling plan and variant ids for the price test.
 * Items with the initialPlanId and variantId should be updated to the newPrice and newPlanId.
 */
export type PriceMapping = {
  newPrice: number;
  initialPlanId: number;
  newPlanId: number;
  variantId: number;
};
